.button {
    border-radius:1000px;
    border:none;
    padding: 4px 15px;
    height:30px;
    &.primary {
        color: var(--primary);
        background-color: var(--gray);
    }
    &.secondary {
        color: var(--text-secondary);
        background-color: var(--primary);
    }
    &.ghost {
        color: var(--primary);
        background-color: transparent;
        border: 1px solid var(--primary);
    }
}