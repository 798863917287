@import "../../styles/circle.scss";

.carrier-schedule-card {
    display: flex;
    flex-direction: column;
    border: var(--border-primary);
    border-radius: 10px;
    background-color: #ffffff;

    >.carrier-schedule-card-top {
        padding: 15px;
        display: flex;
        justify-content: space-between;

        >.carrier-schedule-card-top-left {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 7px;
           
            >.carrier-schedule-carrier {
                display: flex;
                gap: 5px;
                align-items: center
            }

            >.carrier-schedule-campaign {
                display: flex;
                margin-top: 8px;
                padding: 0px 15px;
                height: 30px;
                align-items: center;
                background-color: pink;
                border-radius: 40px;
            }
        }

        >.carrier-schedule-card-top-right {
            display: flex;
            align-items: flex-start;
            gap: 15px;

            >.edit-carrier-schedule-button {
                background-color: transparent;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                height: 30px;
            }

        }

    }
    >.carrier-schedule-total-card-wrapper {
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        >.salad-dot-wrapper-in-card.darker-bg {
                height: 40px;
                padding: 0px 10px;
                align-items: center;
                display: flex;
                gap: 5px;
                border-top: var(--border-primary);
                border-right: var(--border-primary);
                background-color: #F8F9F8;
                &:last-child {
                    border-right: none;
                }

                >.amount-salad-text {
                    display: flex;
                    gap: 2px;
                }
            }
    }

    >.carrier-schedule-card-bottom {
       border-bottom:var(--border-primary);
    }
    >.carrier-schedule-actions-wrapper {
        padding:15px;
        display:flex;
        justify-content: flex-end;
    }
}