@import "../../../styles/circle.scss";

.delete-button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 10px;

    >.delete-button-content {
        display: flex;

        align-items: center;
        gap: 10px;

        >.delete-button-cricle-40 {
            position: relative;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2px;
            background-color: var(--alert-bg);
            color:var(--alert);
            @include circle(40px);
            border: 1px solid var(--alert);    
        }

    }


}