.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
  margin: 50px auto;
}

.inputGroup {
  margin-bottom: 10px;
  width: 100%;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
}

.inputGroup input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.loginButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.loginButton:hover {
  background-color: #0056b3;
}
