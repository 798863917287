@import '../../styles/circle.scss';

.total-order-card {
    display: flex;
    flex-direction: column;
    border: var(--border-primary);
    border-radius: 10px;
    background-color: #ffffff;

    >.total-order-card-top {
        padding: 15px;
        display: flex;
        justify-content: space-between;

        >.total-order-card-top-left {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap:16.5px;

            >.total-orders-amount-wrapper {
                display: flex;
                gap: 10px;
            }
        }

        >.total-order-card-top-right {
            display: flex;
            align-items: flex-start;
            gap: 15px;

            >.edit-order-button {
                background-color: transparent;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                height: 30px;
            }

            >.add-order-to-list-button {
                background-color: transparent;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #ffffff;
                background-color: var(--primary);
                @include circle(30px);

            }

        }

    }

    >.total-order-card-bottom {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        >.salad-dot-wrapper-in-card {
            height: 40px;
            padding: 0px 10px;
            align-items: center;
            display: flex;
            gap: 5px;
            border-top: var(--border-primary);
            border-right: var(--border-primary);

            &:last-child {
                border-right: none;
            }

            >.amount-salad-text {
                display: flex;
                gap: 2px;
            }
        }
    }
}