@import "../../../styles/circle.scss";

.carrier-schedule-order-card {
    display: flex;
    flex-direction: column;
    

    >.carrier-schedule-order-card-top {
        height:30px;
        padding: 0px 15px;
        display: flex;
        justify-content: space-between;
        border-top: var(--border-primary);
        align-items: center;
        >.carrier-schedule-order-card-top-left {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 7px;
            >.cut-length {
                    text-overflow: ellipsis;
                    text-wrap:nowrap;
                    overflow: hidden;

                }
        }

        >.carrier-schedule-order-card-top-right {
            display: flex;
            align-items: flex-start;
            gap: 15px;

        }

    }

    >.carrier-schedule-order-card-bottom {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        >.salad-dot-wrapper-in-card {
            height: 40px;
            padding: 0px 10px;
            align-items: center;
            display: flex;
            gap: 5px;
            border-top: var(--border-primary);
            border-right: var(--border-primary);

            &:last-child {
                border-right: none;
            }

            >.amount-salad-text {
                display: flex;
                gap: 2px;
            }
        }
    }
}