.spinner {
    width: 100%;
    display: flex;
    justify-content: center;

    i {
        animation: spin 500ms ease-out infinite;

        @keyframes spin {
            100% {
                transform: rotate(360deg);
            }
        }
    }
}