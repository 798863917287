@import "./variables.scss";
@import "./circle.scss";

body {
    min-width: 1300px;
    padding: 20px 30px 30px 30px;
    font-family: 'inter', sans-serif;
    color: var(--primary);
    background-color: #FAFAFB;
}

.green-button {
    background-color:var(--primary);
    display:flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 1000px;
    color: #fff;
    padding:20px 15px;
    height:40px;
}

.green-button:disabled {
    background-color: rgb(236, 236, 236);
    color: darkgray;
    cursor: not-allowed;
}

.popup-btn {
    display:flex;
    justify-content: right;
}
input,
select {
    border-radius:40px;
    border: var(--border-primary);
    height:40px;
}

input {
    padding: 20px 15px;
}
select {
    padding: 0px 15px;
    background: url("../assets/images/select-arrow.svg") no-repeat right;
    background-position: center right 15px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    >.placeholder {
        color: gray;
    }
        
}