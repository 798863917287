.create-retailer-popup {
    overflow-x: scroll;
    padding-bottom: 30px;

    >.create-retailer-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        >.retailer-section {
            display: flex;
            flex-direction: column;
            gap: 7px;
        }

        >.retailer-car-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            >.retailer-schedule-section {
                display: flex;
                flex-direction: column;
                gap: 7px;
            }

            >.retailer-section {
                display: flex;
                flex-direction: column;
                gap: 7px;
            }
        }

        >.retailer-save-wrapper {
            margin-top: 10px;
        }
    }
}