@import "../../styles/circle";

.navbar {
    position: sticky;
    top: 15px;
    z-index: 100;
    display: flex;
    background: var(--primary);
    border: var(--border-primary);
    border-radius: 10px;
    padding: 0px 20px;
    margin-bottom: 20px;
    height: 60px;
    box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.1);
    
    >.nav-items--wrapper {
        display:flex;
        flex-direction: colu;
        justify-content: space-between;
        align-items: center;
        width:100%;

        >.nav-items {
                display: flex;
                align-items: center;
                gap: 30px;

        
               
                >.nav-item {
                    &.nav-item-settings {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #fff;
                            color:var(--primary);
                            @include circle(40px);
                            border: var(--border-primary);
                    
                            &.active {
                                background: #fff;
                                color: yellowgreen;
                            }
                        }
                
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    text-decoration: none;
        
                    &.active {
                        color: greenyellow;
                    }
                }
            }
    }

   
}