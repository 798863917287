@import "../../styles/circle.scss";

.home-page-wrapper {
    >.home-week-filter-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        >.home-salad-package-dots-wrapper {
                display: flex;
                align-items: center;
                gap: 15px;

                >.salad-dot-wrapper {
                    display:flex;
                    align-items: center;
                    gap:5px;
                    color:#818094;
                }
        }
    }
    >.home-grid-layout {
        margin-top: 20px;
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        gap:20px;
        .home-order-column {
            display: flex;
            flex-direction: column;
            gap:25px;
            .home-order-list-wrapper {
                display:flex;
                flex-direction: column;
                
                gap:15px;

                >button {
                    position: relative;
                    margin-right:auto;
                }
            }
        }
        .home-carrier-schedules-wrapper {
            display:flex;
            gap:25px;
            flex-direction: column;
            >.home-carrier-schedules-list-wrapper {
                display: flex;
                flex-direction: column;
                gap:15px;
                >.home-carrier-schedules-filter-wrapper {
                        opacity: 0.2;
                        display: flex;
                     
                        gap: 15px;
                    }
            }  
            
        }
        .home-departures-wrapper {
            display:flex;
            flex-direction: column;
            gap:25px;
            >.home-departure-schedules-list-wrapper {
                display: flex;
                flex-direction: column;
                gap:15px;
                >.home-departure-schedules-btns-wrapper {
                    min-height:30px;
                    opacity: 0.2;
                }
            }
        }
    }
    
}


