.edit-schedule-popup {
    overflow-x: scroll;
    padding-bottom: 30px;

    >.edit-schedule-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        >.schedule-section {
            display: flex;
            flex-direction: column;
            gap: 7px;
            >.remove-from-schedule-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    margin: 15px;
            
                }
            >.schedule-location-section {
                    display: flex;
                    flex-direction: column;
                    border-radius:10px;
                    border: var(--border-primary);

                    >.schedule-order-location {
                        margin:15px;
                        
                    } 
                    >.schedule-amount-section {
                            display: flex;
                    
                            >.salad-dot-wrapper-in-card {
                                width: 100%;
                                height: 40px;
                                padding: 0px 10px;
                                align-items: center;
                                display: flex;
                                gap: 5px;
                                border-top: var(--border-primary);
                                border-right: var(--border-primary);
                    
                                &:last-child {
                                    border-right: none;
                                }
                    
                                >.amount-salad-text {
                                    display: flex;
                                    gap: 2px;
                                }
                            }
                        }

                        
                   
            }

            
                         
        }

        >.schedule-save-delete-wrapper {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
        }
    }
}