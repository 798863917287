@import "../../styles/circle.scss";

.order-card {
    display:flex;
    flex-direction: column;
    border: var(--border-primary);
    border-radius:10px;
    background-color: #ffffff;
    >.order-card-top {
        padding:15px;
        display: flex;
        justify-content: space-between;
        >.order-card-top-left {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap:7px;
            
            >.order-carrier {
                display:flex;
                gap:5px;
                align-items:center
            }
            >.order-campaign {
                display: flex;
                margin-top:8px;
                padding: 0px 15px;
                height:30px;
                align-items: center;
                background-color: pink;
                border-radius:40px;
            }
        }
        >.order-card-top-right {
            display: flex;
            align-items: flex-start;
            gap:15px;
            >.edit-order-button {
                background-color:transparent;
                border: none;
                display:flex;
                justify-content: center;
                align-items: center;
                gap:10px;
                height:30px;
            }
            >.add-order-to-list-button {
                background-color:transparent;
                border: none;
                display:flex;
                justify-content: center;
                align-items: center;
                gap:10px;
                color: #ffffff;
                background-color: var(--primary);
                @include circle(30px);
                
            }
            
        }
        
    }
    >.order-card-bottom {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        >.salad-dot-wrapper-in-card {
            height:40px;
            padding: 0px 10px;
            align-items: center;
            display:flex;
            gap:5px;
            border-top:var(--border-primary);
            border-right: var(--border-primary);
            &:last-child {
                border-right:none;
            }
            >.amount-salad-text {
                display:flex;
                gap:2px;
            }
        }
    }
}