.forecast-page-wrapper {
    >.forecast-settings-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        >.forecast-setting-buttons-wrapper {
            display:flex;
            gap:10px;
        }
    }
}