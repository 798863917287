.create-order-popup {
    overflow-x: scroll;
    padding-bottom: 30px;

    >.create-order-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        >.order-section {
            display: flex;
            flex-direction: column;
            gap: 7px;
            >.order-estimate-wrapper {
                display:flex;
                gap:10px;
                >.weekday-selector {
                    padding-right:40px;
                
                }   
            }
            >.order-variants-flexer {
                display:flex;
                gap:10px;
                justify-content: space-between;

                >.order-variant-section {
                        display: flex;
                        flex-direction: column;
                        gap: 7px;
                        justify-content: space-between;
                        max-width:calc(25% - 10px);
                        >.salad-dot-wrapper {
                            display: flex;
                            gap: 7px;
                            align-items: center;
                        }
                    }
            }
           
        }

        >.order-save-wrapper {
            margin-top: 10px;
        }
    }
}