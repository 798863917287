@import '../../styles/circle.scss';

.popup-wrapper {

    position: fixed;
    display:flex;
    justify-content: flex-end;
    right: 0;
    top: 0;
    z-index: 100000;
    
    width:100vw;
    height:100vh;
    >.popup {
        width: 600px;
        display:flex;
        flex-direction: column;
        gap:30px;
        padding: 30px 20px;
        background-color: #fff;
        overflow:scroll;
        >.top {

                height:40px;
               
                display: flex;
                align-items: center;
                border-radius: 20px 20px 0 0;
                >.close-button-wrapper {
                    display:Flex;
                    margin-left:auto;
                    gap:10px;
                    align-items: center;
                    >.close-button {
                            @include circle(30px);
                            background: var(--primary);
                            color: #fff;
                            margin-left: auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: none;
                        }

                }
                
            }
    }
}