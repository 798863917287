:root {
    --primary: #193722;
    --gray: #ECECEC;
    --text-primary: #193722;
    --text-secondary: #ffffff;
    --text-gray:#818094;
    --border-primary: 1px solid #E4E4FF;
    --alert: #E43B3B;
    --alert-bg: #FAD8D8;
}

/* Salad dot utility */
.dot{
    width:6px;
    height:6px;
    border-radius:40px;
    &.mix-60 {
        background-color: #1861B7;
    }
    &.mix-150 {
        background-color: #7B18B7;
    }
    &.krisp-60 {
        background-color: #F26B1B;
    }
    &.krisp-150 {
        background-color: #F2D01E;
    }
}


/*font utility classes*/
.text-color-primary {
    color: var(--text-primary);
}
.text-color-gray {
    color: var(--text-gray);
}

.text-s-m {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
}

.text-m-m {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.text-m-l {
    font-size: 32px;
    line-height: 42px;
    font-weight: 500;
}

.text-l-s {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
}
.text-l-m {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
}

