.edit-campaign-popup {
    overflow-x: scroll;
    padding-bottom: 30px;

    >.edit-campaign-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        >.campaign-section {
            display: flex;
            flex-direction: column;
            gap: 7px;
        }

        >.campaign-save-delete-wrapper {
            margin-top: 10px;
            display:flex;
            justify-content: space-between;
        }
    }
}