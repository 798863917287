@import "../../../styles/circle.scss";
.create-button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 10px;
    >.create-button-content {
        display: flex;

        align-items: center;
        gap:10px;
        >.create-button-cricle-20 {
                position: relative;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 2px;
                background-color: var(--primary);
                @include circle(20px);
            }
        >.create-button-cricle-30 {
            position: relative;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2px;
            background-color: var(--primary);
            @include circle(30px);
        }
                 >.create-button-cricle-40 {
                     position: relative;
                     color: #ffffff;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                     padding-bottom: 2px;
                     background-color: var(--primary);
                     @include circle(40px);
                 }
    }
    

}