.create-car-popup {
    overflow-x: scroll;
    padding-bottom: 30px;

    >.create-car-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        >.car-section {
            display: flex;
            flex-direction: column;
            gap: 7px;
            >.i-car-capacity {
                    text-align: right;
                }
            >.car-pickup-estimate {
                display:flex;
                gap:10px;
                >.weekday-selector {
                    padding-right:40px;
                
                }
            }
        }

        >.car-car-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            >.car-schedule-section {
                display: flex;
                flex-direction: column;
                gap: 7px;
            }

            >.car-section {
                display: flex;
                flex-direction: column;
                gap: 7px;

               
            }
        }

        >.car-save-wrapper {
            margin-top: 10px;
        }
    }
}