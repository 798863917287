.settings-odling-popup {
    >.settings-odling-form {
        >.settings-section {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-top: 30px;
            padding-bottom: 30px;
            border-top: var(--border-primary);

            &.last-setting-section {
                border-bottom: var(--border-primary);
            }

            >.setting-input-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                >select {
                    padding-right: 50px;
                }
            }

            >.setting-edgecase-wrapper {
                display: flex;
                justify-content: flex-end;
            }
        }

        >.save-settings-wrapper {
            display: flex;
            margin-top: 30px;
            margin-bottom: 50px;
        }
    }
}