.create-location-popup {
    overflow-x: scroll;
    padding-bottom:30px;
    >.create-location-form{
        display: flex;
        flex-direction: column;
        gap:20px;
        >.location-section {
            display: flex;
            flex-direction: column;
            gap:7px;
        }
        >.location-save-wrapper{
            margin-top:10px;
        }
    }   
}