
.locations-page-wrapper {
    padding-bottom:500px;
    display: flex;
    flex-direction: column;
    gap:20px;
    >.locations-actions-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            align-items: center;
        >.locations-create-wrapper {
            display:flex;
            gap:30px;
        }
        >.locations-search-wrapper {
            display:flex;
            gap:15px;
            >.locations-search-input {
                font-size: 14px;
            }
        }


    }
}

.load-more-wrapper {
    display:flex;
    justify-content: center;
}

.locations-table {
    border-radius: 10px;
    border: var(--border-primary);
    width: 100%;
    border-collapse: inherit;
    background-color: #ffffff;

    >thead {
        >tr {
            >th {
                &:first-child {
                    padding-left: 20px;
                    
                }

                height: 40px;
                padding:0px 10px;
                text-align: left;
                vertical-align: middle;
                color:var(--text-gray);
                font-size:14px;
                line-height: 20px;
                font-weight:500;
                top:auto;
                bottom:auto;
            }
        }
    }

    >tbody {
        >tr {
            cursor:pointer;
            >td {
                &:first-child {
                    padding-left: 20px;
                    width:300px;
                }
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                border-top: var(--border-primary);
                border-right: var(--border-primary);
                vertical-align: middle;
                font-size:14px;
                line-height: 20px;
                font-weight:500;
                height:40px;
                padding: 0px 10px;
                width:200px;

                &:last-child {
                    border-right: none;
                    width:300px
                }
            }
        }
    }
}
