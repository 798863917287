.create-carrier-popup {
    overflow-x: scroll;
    padding-bottom: 30px;

    >.create-carrier-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        >.carrier-section {
            display: flex;
            flex-direction: column;
            gap: 7px;
        }

        >.carrier-car-section {
            display: flex;
            flex-direction: column;
            gap: 20px;
            >.carrier-schedule-section {
                display: flex;
                flex-direction: column;
                gap: 7px;
            }
            >.carrier-section {
                display: flex;
                flex-direction: column;
                gap: 7px;
            }
        }

        >.carrier-save-wrapper {
            margin-top: 10px;
        }
    }
}